








































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';

import VideoPlayer from '@/components/VideoPlayer.vue';
import BackButton from '@/components/ui/BackButton.vue';
import TagList from '@/components/ui/TagList.vue';

@Component({
	components: {
		BackButton,
		TagList,
		VideoPlayer
	},
	computed: {
		...mapState([
			'user'
		])
	}
})
export default class Video extends Vue {
	@Prop() video!: any;

	loaded = false;
	playing = false;

	get containerWidth(): number {
		const videobox = this.$refs.videobox as HTMLElement;
		return videobox.clientWidth;
	}

	get videoOptions(): any {
		return {
			id: this.video.vimeoId,
			width: this.containerWidth,
		}
	}

	get placeholderAttrs() {
		return {
			class: '',
		}
	}

	get showBack() {
		return !this.playing;
	}

	togglePlay() {
		this.playing = !this.playing;
	}

	mounted() {
		this.loaded = true;
	}
}
